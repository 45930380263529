@import "src/_mixin.scss";

.activityFeedContainer {
  position: relative;

  @include media('mobile') {
    max-width: initial;
  }

  &__intersectingWrapper {
    min-height: 50px;

    @include media('mobile') {
      min-height: 30vh;
    }
  }

  // Be aware that if you change max-height value here you should also change it in ActivityFeedContainer.js file
  &__itemsContainer {
    overflow: hidden;
    max-height: 585px;

    @include media('mobile') {
      max-height: 430px;

      &_mobileShortVersion {
        max-height: 290px;
      }
    }

    &_desktopShortVersion {
      @include min_media('medium') {
        max-height: 290px;
      }
    }
  }

  &__scrollableContainer {
    height: 100%;
    transition: 0.4s transform; // @scrollTransitionDuration
  }

  &__controlButtonContainer {
    flex-grow: 0;

    &_up {
      position: absolute;
      top: -35px;
      width: 100%;
      display: none; // None by default, but managed on JS side
    }

    &_down {
      z-index: 1;
      position: relative;
      margin-top: -65px;
      padding-top: 70px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, $white 70%);
    }

    &_withGreyBackground {
      background: linear-gradient(to bottom, transparentize($grey-settings, 1) 0, $grey-settings 70%);
    }
  }

  &__controlButton {
    @include focus_state;
    width: 40%;
    height: 30px;
    margin: auto;
    padding-top: 5px;
    display: block;
    text-align: center;
    border-radius: $global-border-radius;
  }

  &__controlButtonIcon {
    display: inline-block;
    width: 52px;
    height: 17px;
    background-image: url("~common/icons/activity_feed_scroll_arrow.svg");
    background-repeat: no-repeat;
  }

  &__controlButton_up > &__controlButtonIcon {
    transform: rotateX(180deg);
  }

  &__controlButtonLabel {
    // Visually hidden styles taken from first link in google
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  &__transitionGroup {
    transform: translateZ(0);
  }

  &__transitionItem {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      opacity: 1;
      transform: scale(1);
      transition: opacity 500ms ease-in, transform 0.5s;
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      transition: opacity 500ms ease-in;
    }
  }
}
