@import "src/_mixin.scss";

.ratingStars {
  display: flex;
  align-items: center;

  &__star {
    display: inline-block;
    margin-right: 5.5px;
    width: 20px;
    height: 19px;
    background-size: contain;
    background-repeat: no-repeat;

    &_big {
      width: 25px;
      height: 23px;
      margin-right: 13px;
    }

    &_x-big {
      width: 35px;
      height: 33px;
      margin-right: 13px;
    }

    &_small {
      width: 12px;
      height: 12px;
      margin-right: 3px;
    }

    &_normal {
      width: 15px;
      height: 15px;
    }

    &:last-child {
      margin-right: 0;
    }

    &_gold {
      background-image: url("../icons/new_gold_star.svg");
    }

    &_gray {
      background-image: url("../icons/new_grey_star.svg");
    }

    &_grayOutlined {
      background-image: url("../icons/new_grey_star_outline.svg");
    }
  }
}
