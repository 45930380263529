@import "src/_mixin.scss";

.activityFeedAction {
  @include border;
  display: flex;
  flex-flow: row;
  margin-bottom: 10px;
  padding: 15px;
  background: $white;
  border-radius: $global-border-radius;
  box-shadow: $conversation-block-shadow;

  &__avatar {
    @include avatar(35px);
    margin-right: 15px;
    flex-basis: 35px;
    min-width: 35px;
    overflow: hidden;

    &_conversation {
      width: 30px;
      height: 30px;
      min-width: 30px;
      max-width: 30px;
      margin: 5px 5px 10px 0;
      display: inline-block !important;
    }

    &_reviewUser {
      width: 30px;
      height: 30px;
      min-width: 30px;
      max-width: 30px;
      margin-right: 0;
    }

    &_reviewProvider {
      width: 32px;
      height: 32px;
      min-width: 32px;
      max-width: 32px;
      margin: 0 10px 0 -20px;
      border: 2px solid $white;
      transition: transform .2s;
      transition-delay: .2s;
    }

    &_reviewUser:hover + &_reviewProvider,
    &_reviewProvider:hover {
      transform: translateX(20px);
    }
  }

  &__truncatedAvatarsCounter {
    @include lato_font(16px, 30px, 400, $text-light-grey);
    margin: 5px 5px 10px;
    display: inline-block;
    vertical-align: top;
  }

  &__content {
    @include lato_font(16px, 23px, 400, $text-dark-grey);
    margin-bottom: 5px;
    word-break: break-word;

    a {
      @include focus_state;
      border-radius: $global-border-radius;
    }

    .pseudo-link {
      color: $link-color;
    }
  }

  &__dateCityInfo {
    @include lato_font(13px, 16px, 400, $text-light-grey);
    margin: 0;
  }

  &__ratingContainer {
    display: flex;
    flex-flow: row nowrap;
    margin: 5px 0 10px;
  }

  &__ratingStars {
    height: 17px;
  }

  &__star {
    height: 17px;
    width: 18px;
  }

  &__ratingTextRepresentation {
    @include lato_font(13px, 19px, 400, $text-dark-grey);
    margin-left: 8px;
  }
}
